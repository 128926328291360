import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBdH7EknH_ueFTYn6iawswIQD4AmogwvPk',
  authDomain: 'dronline-5572a.firebaseapp.com',
  databaseURL: 'https://dronline-5572a.firebaseio.com',
  projectId: 'dronline-5572a',
  storageBucket: 'dronline-5572a.appspot.com',
  messagingSenderId: '614851761421',
  appId: '1:614851761421:web:b58b43778b6992f85acc44',
  measurementId: 'G-S5BJLKRVKV',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LelgcMpAAAAAIfgcBiYuNpeoBUm3AxNgjPG21fA'),
  isTokenAutoRefreshEnabled: true,
});

export { app, auth, appCheck, analytics };
