import { lazy } from 'react';

const options = [
  // {
  //   key: Math.random(),
  //   path: '/inventories',
  //   component: lazy(() => import('../inventory/List')),
  //   exact: true,
  // },
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../dashboard/Container')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/myprofile',
    component: lazy(() => import('../profile/MyProfile')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/schedule',
    component: lazy(() => import('../schedule/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/telemedicine-schedule',
    component: lazy(() => import('../schedule/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/clinic-users',
    component: lazy(() => import('../person/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/patient',
    component: lazy(() => import('../patient/Patient')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/edit-patient-profile/:id',
    component: lazy(() => import('../patient/PatientDEPRECATED')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/symptom',
    component: lazy(() => import('../symptom/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/visitList',
    component: lazy(() => import('../visitList/VisitList')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/telemedicine-visit',
    component: lazy(() => import('../visitList/VisitList')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/international-telemedicine-visit',
    component: lazy(() => import('../visitList/VisitList')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/booking',
    component: lazy(() => import('../visit/Booking')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/disease',
    component: lazy(() => import('../disease/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/visit/:id',
    component: lazy(() => import('../visit/Visit')),
    exact: true,
  },
];
export default options;
