import React, { Component } from 'react';
import {
  Row, Col, Form, Input, Button,
  Card, Select, AutoComplete,
  notification,
} from 'antd';
import { observer, inject } from 'mobx-react';
import getAgentInstance from '../../helpers/superagent';
import { auth } from "../../helpers/firebase";
import { RecaptchaVerifier, isSignInWithEmailLink, signInWithPhoneNumber, sendSignInLinkToEmail, signInWithEmailLink } from "firebase/auth";

const superagent = getAgentInstance();

@inject('userStore', 'tokenStore')
@observer
class DoctorLogin extends Component {
  constructor() {
    super();
    this.initialState = () => ({
      loading: false,
      phoneNo: undefined,
    });

    this.state = this.initialState();

    this.goBack = () => {
      this.setState({
        phoneNo: undefined,
      });
    };

    this.sendCode = (values) => {
      const zis = this;
      zis.setState({ loading: true });

      const generatedPhoneNo = `${values.country_code}${values.phone_no.replace(/\s/g, '')}`;

      window.grecaptcha.getResponse(window.recaptchaWidgetId);
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, `+${generatedPhoneNo}`, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          zis.setState({ phoneNo: generatedPhoneNo });
          zis.setState({ loading: false });
        }).catch((error) => {
          notification.error({
            message: 'Sorry..',
            description: error.code,
            placement: 'bottomRight',
          });
          window.recaptchaVerifier.render().then((widgetId) => {
            window.grecaptcha.reset(widgetId);
          });
          zis.setState({ loading: false });
        });

    };
    this.doLogin = (values) => {
      const { code } = values;
      const { phoneNo } = this.state;
      window.confirmationResult.confirm(code).then(async (result) => {
        const { user } = result;
        const idToken = await user.getIdToken();
        await this.getAuthData(phoneNo, idToken);
      }).catch((error) => {
        notification.error({
          message: 'Sorry..',
          description: error.code,
          placement: 'bottomRight',
        });
      });
    };
    this.getAuthData = (phoneNo = '', idToken = '') => {
      const { userStore, tokenStore } = this.props;
      this.setState({ loading: true });
      superagent
        .post('/doctor/auth/phone')
        .send({
          id_token: idToken,
          phone_no: phoneNo,
        })
        .set('Content-Type', 'application/json')
        .type('json')
        .end((err, res) => {
          this.setState({ loading: false });
          if (!err) {
            const { body } = res;
            userStore.set(body.data);
            tokenStore.set(body.token);
          }
        });
    };
  }

  componentDidMount() {
    if (window) {
      const recaptchaOptions = {
        siteKey: "6LelgcMpAAAAAIfgcBiYuNpeoBUm3AxNgjPG21fA",
        size: "invisible",
        callback() {
        },
      };
      window.recaptchaVerifier = new RecaptchaVerifier( auth,
        "recaptcha",
        recaptchaOptions,
      );
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    }
  }

  render() {
    const { loading, phoneNo } = this.state;
    const { loginHandler } = this.props;
    return (
      <Col span={24} offset={1}>
        <Row>
          <Col span={24}>
            <Card bordered={false}>
              {phoneNo === undefined
                ? (
                  <Form
                    name="basic"
                    initialValues={{}}
                    layout="vertical"
                    onFinish={this.sendCode}
                  >
                    <Form.Item
                      label="Doctor phone number"
                      name="phone_no"
                      rules={[
                        {
                          required: true,
                          message: 'Phone number is required',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter doctor's phone number"
                        addonBefore={(
                          <Form.Item name="country_code" noStyle initialValue="964">
                            <Select style={{ width: 70 }}>
                              <Select.Option value="964">964</Select.Option>
                            </Select>
                          </Form.Item>
                        )}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <div id="recaptcha" />
                    </Form.Item>
                    <Form.Item
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <Row>
                        <Col span={6} style={{ textAlign: 'left' }}>
                          <Button
                            type="link"
                            onClick={loginHandler}
                            style={{ padding: 0 }}
                          >
                            Clinic User?
                          </Button>
                        </Col>
                        <Col span={18}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            Send
                          </Button>
                        </Col>
                      </Row>

                    </Form.Item>
                  </Form>
                )
                : null}

              {phoneNo !== undefined ? (
                <Form
                  name="basic"
                  initialValues={{}}
                  layout="vertical"
                  onFinish={this.doLogin}
                >
                  <Form.Item
                    label="Verify"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: 'Verification code is required',
                      },
                    ]}
                  >
                    <AutoComplete />
                  </Form.Item>
                  <Form.Item>
                    <Row>
                      <Col span={13}>
                        <Button
                          type="link"
                          style={{ padding: 0, color: 'gray' }}
                          onClick={
                            this.goBack
                          }
                        >
                          Back
                        </Button>
                      </Col>
                      <Col span={11} style={{ textAlign: 'right' }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              ) : null}

            </Card>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default DoctorLogin;
