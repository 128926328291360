import React from 'react';
import {
  Row, Col, Form, Input, Button, Card,
} from 'antd';
import { observer, inject } from 'mobx-react';
import getAgentInstance from '../../helpers/superagent';

const superagent = getAgentInstance();

@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class SecretaryLogin extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({
      loading: false,
    });
    this.state = this.initialState();

    this.onFinish = (values) => {
      const { userStore, tokenStore } = this.props;
      this.setState({ loading: true });
      superagent
        .post(`${process.env.REACT_APP_API_LINK}/doctor/doctorperson/auth`)
        .send({ username: values.username, password: values.password })
        .end((err, res) => {
          this.setState({ loading: false });
          if (!err) {
            const { body } = res;
            userStore.set(body.data);
            tokenStore.set(body.token);
          }
        });
    };
  }

  render() {
    const { loginHandler } = this.props;
    const { loading } = this.state;

    return (

      <Row>
        <Col span={24}>
          <Card bordered={false}>
            <Form
              name="basic"
              initialValues={{}}
              layout="vertical"
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    min: 3,
                    message: 'Minimum length for username is 3 characters!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col span={18}>

                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.preventDefault();
                        loginHandler();
                      }}
                    >
                      Doctor?
                    </Button>
                  </Col>
                  <Col span={6} style={{ textAlign: 'right' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}

                    >
                      Login
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default SecretaryLogin;
